/* Add more CSS for overall table styling */
.table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #B9B9B9;
    border-radius: 4px;
}

.table th,
.table td {
    border: 1px solid #B9B9B9;
    text-align: left;
    padding: 8px;
}

.table th {
    background-color: #f2f2f2;
}