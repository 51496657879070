@tailwind base;
@tailwind components;
@tailwind utilities;

/* DataTable.css */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto; /* Adjust this as necessary to fit the content */
    min-width: 350px; /* Provide a minimum width to handle smaller content gracefully */
    max-width: 400px; /* Max width to prevent it from becoming too wide */
    overflow: hidden; /* Hide overflow content */
    white-space: nowrap; /* Keep content in a single line, remove if you want multiline */
    text-overflow: ellipsis; /* Show ellipsis if content overflows */
  background-color: black;
  color: white;
  text-align: left;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: 30px;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.text-2xs {
  font-size: 0.65rem;
  line-height: 0.9rem;
}

.fadeIn {
  animation: fadeIn 0.5s forwards;
}

.fadeOut {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

a:hover {
  opacity: 0.8;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.active {
  background-color: #717171;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
